.video-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #F1F1F1;
    padding: 20px;
    box-sizing: border-box;
}

.video-container {
    width: 100%;
    max-width: 1000px;
    max-height: 1000px;
    /* Adjust based on your preference */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
    overflow: hidden;
    /* Ensures the iframe corners are also rounded */
}

.responsive-video {
    width: 100%;
    height: 450px;
    /* Adjust height based on the aspect ratio of your video */
}

@media (max-width: 768px) {
    .responsive-video {
        height: 300px;
        /* Adjust for smaller screens */
    }
}

.navigate-button {
    padding: 10px 20px;
    margin: 20px auto;
    font-size: 1.2rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
}

.navigate-button:hover {
    background-color: #0056b3;
}