/* Resetting box-sizing for all elements */
*,
*::before,
*::after {
    box-sizing: border-box;
}

html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    /* Ensure html and body are at least the height of the viewport */
}

body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    /* Apply font family to body for consistency */
    overflow-y: auto;
    /* Ensure vertical scroll is enabled if content overflows */
}

/* Global styles scoped to the consent form page */
.consent_form {
    min-height: 100vh;
    /* Ensure form container takes up at least the full height of the viewport */
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #F1F1F1;
    padding: 20px;
    overflow-y: auto;
    /* Allow scrolling within the form container if content overflows */
}

.divider {
    border-top: 1px solid #333; /* Adjust the border style as needed */
    margin: 0px 0; /* Adjust the margin to control the spacing */
  }

/* Styles for the form container and its child elements */
.consent_form .form_container {
    background-color: #fff;
    padding: 2rem;
    border-radius: 0.5rem;
    width: 100%;
    max-width: 1000px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin: 20px;
}

.consent_form .form_container h2 {
    /* font-size: 1.8rem; */
    color: #333;
    /* margin-bottom: 5px; */
    align-self: center;
    text-align: center;
    margin-top: 0px;
}
.consent_form .form_container h2,
.consent_form .form_container h3,
.consent_form .form_container p {
    color: #333;
    /* Unified color for headings */
    margin-bottom: 0px;
    margin-top: 0px;
}

.consent_form .form_container p {
    line-height: 1.6;
    color: #666;
}

.consent_form .form_container form {
    display: flex;
    flex-direction: column;
}

.consent_form .form_container label {
    margin-top: 1rem;
    font-size: 1rem;
    color: #333;
}

.consent_form .form_container select,
.consent_form .form_container input,
/* Assuming you might have inputs */
.consent_form .form_container textarea {
    /* Assuming you might have textareas */
    margin-top: 0.5rem;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 0.3rem;
    font-size: 1rem;
    width: 100%;
    /* Ensure form elements do not overflow their container */
}

.cont_button {
    display: block;
    padding: 10px 20px;
    margin: 20px auto;
    font-size: 1.2rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
}

.cont_button:hover {
    background-color: #0056b3;
}

/* Responsive Design */
@media (max-width: 768px) {
    .consent_form .form_container {
        padding: 1rem;
        margin: 10px;
    }
}