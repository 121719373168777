/* Resetting box-sizing for all elements */
*,
*::before,
*::after {
    box-sizing: border-box;
}

html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
}

body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    overflow-y: auto;
    overflow-x: hidden;
}

/* Global styles scoped to the survey form page */
.pesurvey_form {
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #F1F1F1;
    padding: 20px;
    overflow-y: auto;
}

/* Styles for the form container and its child elements */
.pesurvey_form .peform_container {
    background-color: #fff;
    padding: 2rem;
    border-radius: 0.5rem;
    width: 100%;
    max-width: 1000px;
    /* Adjusted for survey form specifics */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin: 20px;
}

.pesurvey_form .peform_container h2,
.pesurvey_form .peform_container p {
    color: #333;
    margin-bottom: 10px;
}

.pesurvey_form .peform_container p {
    line-height: 1.6;
    color: #666;
}

.pesurvey_form .peform_container form {
    display: flex;
    flex-direction: column;
}

.pesurvey_form .peform_container .qLabel {
    margin-top: 1rem;
    font-size: 1.2rem;
    color: #333;
    font-weight: bold;
}

.pesurvey_form .peform_container .aLabel {
    margin-top: 1rem;
    font-size: 1.2rem;
    color: #333;
    font-weight: normal;
}

.pesurvey_form .peform_container select,
.pesurvey_form .peform_container input {
    margin-top: 0.5rem;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 0.3rem;
    font-size: 1.2rem;
    width: 100%;
}

.cont_button {
    display: block;
    padding: 10px 20px;
    margin: 20px auto;
    font-size: 1.2rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
}

.cont_button:hover {
    background-color: #0056b3;
}

/* Responsive Design */
@media (max-width: 768px) {
    .pesurvey_form .peform_container {
        padding: 1rem;
        margin: 10px;
    }
}