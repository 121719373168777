/* Resetting box-sizing for all elements */
*,
*::before,
*::after {
    box-sizing: border-box;
}

html,
body {
    margin: 10px;
    padding: 0;
    height: 100%;
}

body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    overflow-y: auto;
}

/* Global styles scoped to the demographic form page */
.demographic_form {
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #F1F1F1;
    padding: 20px;
    overflow-y: auto;
}

/* Styles for the form container and its child elements */
.demographic_form .form_container {
    background-color: #fff;
    padding: 2rem;
    border-radius: 0.5rem;
    width: 100%;
    max-width: 1000px;
    /* Adjusted for demographic form specifics */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin: 50px;
    font-size: 1.2rem;
}

.demographic_form .form_container h2 {
    color: #333;
    margin-bottom: 20px;
    text-align: center;
}

.demographic_form .form_container p {
    color: #333;
    margin-bottom: 20px;
}

.demographic_form .form_container p {
    line-height: 1.6;
    color: #666;
}

/* Add margin between div elements within the form container */
.demographic_form .form_container div {
    margin-bottom: 20px; /* Adjust the value as needed */
}

.demographic_form .form_container form {
    display: flex;
    flex-direction: column;
}

.demographic_form .form_container label {
    margin-bottom: 20px;
    font-size: 1.2rem;
    color: #333;
}

.demographic_form .form_container select,
.demographic_form .form_container input {
    margin-top: 0.5rem;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 0.3rem;
    font-size: 1rem;
    width: 100%;
}

/* Responsive Design */
@media (max-width: 768px) {
    .demographic_form .form_container {
        padding: 1rem;
        margin: 20px;
    }
}