.instructions-page {
    background: #F1F1F1;
    min-height: 100vh;
    /* Ensure the minimum height is the full viewport */
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /* Align items from the start to prevent cut-off */
    padding: 20px 20px 80px;
    /* Add padding on the bottom for overflow content */
    box-sizing: border-box;
    /* Include padding in the height calculation */
    overflow-y: auto;
    /* Enable vertical scrolling */
}

.instructions-container {
    background-color: #fff;
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: #333;
    width: 100%;
    max-width: 1000px;
    margin-top: 20px;
    /* Add some top margin to ensure it's not cut off */
    box-sizing: border-box;
    /* Ensure padding is included in the width/height */
}

.instructions-container p{
    font-size: 1.2rem;
}

.ins-navigate-button {
    display: block;
    padding: 10px 20px;
    margin: 20px auto;
    font-size: 1.2rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
}

.ins-navigate-button:hover {
    background-color: #0056b3;
}

/* Ensure code and pre tags are styled for overflow */
code,
pre {
    white-space: pre-wrap;
    /* Allow long lines to wrap */
    word-break: break-word;
    /* Ensure breaks occur between words */
    background-color: #f5f5f5;
    /* Light background for visibility */
    padding: 0.5rem;
    border-radius: 0.25rem;
    display: block;
    /* Ensure block level for proper spacing */
}