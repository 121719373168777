/* Quiz.css */
h2{
    color: black;
    text-align: center;
}
body {
    margin: 0;
    padding: 0;
    background: #F1F1F1;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #fff;
    /* Ensure text is readable on the dark background */
}
.quiz-container {
    background-color: #fff;
    /* Keep the quiz container background white for readability */
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin: 20px auto;
    max-width: 1000px;
    
}

.quiz-question {
    margin-bottom: 1.5rem;
    /* Slightly increased for better separation */
    font-size: 1.1rem;
    color: #333;
    font-weight: bold;
}

.quiz-option {
    display: block;
    padding: 0.5rem 1rem;
    margin: 0.3rem 0;
    /* Adjusted for tighter grouping */
    color: #333;
    background-color: #e7e7e7;
    /* Lighter shade for better readability */
    border: 2px solid transparent;
    border-radius: 0.3rem;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s, transform 0.2s;
    font-weight: normal;
}

.quiz-option:hover,
.quiz-option.selected {
    background-color: #d7d7d7;
    /* Darker on hover for feedback */
    border-color: #007bff;
    /* Highlight color for selection */
    transform: scale(1.02);
    /* Slight enlargement on hover/select */
}

.quiz-feedback {
    margin-top: 0.5rem;
    /* Tightened up to question/answer */
    font-style: italic;
    font-size: 0.9rem;
    /* Slightly smaller font for feedback */
}

.correct-answer {
    color: #28a745;
    /* Green for correct */
}

.wrong-answer {
    color: #dc3545;
    /* Red for wrong */
}

button {
    margin-top: 1rem;
    background-color: #007bff;
    /* A fresh color for the submit button */
    color: #fff;
    border: none;
    padding: 0.6rem;
    font-size: 1.2rem;
    cursor: pointer;
    border-radius: 0.3rem;
}

.cont_button {
    display: block;
    padding: 10px 20px;
    margin: 20px auto;
    font-size: 1.2rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
}

.cont_button:hover {
    background-color: #0056b3;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
    .quiz-container {
        padding: 1rem;
        margin: 10px;
    }

    .quiz-option {
        padding: 0.4rem 0.8rem;
    }

    .quiz-question {
        font-size: 1rem;
        /* Slightly reduced font size for mobile */
    }
}