.game_page {
    background-color: #0d0208;
    /* Dark background */
    color: #00ff00;
    /* Bright green text */
    font-family: 'Courier New', Courier, monospace;
    /* Monospace font */
    height: 100vh;
    /* Full height */
    overflow: hidden;
    /* Hide scrollbars */
}

.game_page h1 {
    text-align: center;
    /* Center the heading */
    padding-bottom: 50px;
    margin-top: 2rem;
    /* Spacing from the top */
    font-size: 2rem;
    /* Larger font size for the heading */
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    padding-bottom: 20px;
    width: 100%;
    height: 70%;
    /* Adjust height as needed */
    box-sizing: border-box;
    background-color: #000;
    /* Background color for the terminal container */
    border: 1px solid #00ff00;
    /* Border to mimic old monitors */
    border-radius: 5px;
    /* Slightly rounded corners */
}

.game_page button {
    
    
    display: block;
    /* Ensure the button is a block element */
    width: 200px;
    /* Width of the button */
    margin: 20px auto;
    /* Margin top and center align horizontally */
    padding: 10px;
    /* Padding inside the button */
    background-color: #0d0208;
    /* Button background color */
    color: #00ff00;
    /* Button text color */
    border: 1px solid #00ff00;
    /* Button border */
    border-radius: 5px;
    /* Slightly rounded corners */
    font-family: 'Courier New', Courier, monospace;
    /* Monospace font */
    cursor: pointer;
    /* Cursor to pointer to indicate it's clickable */
    transition: background-color 0.3s, color 0.3s;
    /* Transition for hover effects */
}

.react-terminal-input{
    font-size: 22px;
    line-height: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.txt-output{
    font-size: 22px;
    line-height: 32px;
    padding-top: 10px;
    background-color: white;
    color: black;
    text-wrap: wrap;
}

.terminal-output{
    font-size: 22px;
    line-height: 16px;
    padding-top: 4px;
    text-wrap: wrap;
    padding-right: 10px;
}
.data-element{

    display: flex;
        /* Ensure the button is a block element */
        width: 300px;
        /* Width of the button */
        margin: 20px auto;
        /* Margin top and center align horizontally */
        padding: 10px;
        /* Padding inside the button */
        background-color: #0d0208;
        /* Button background color */
        color: #00ff00;
        /* Button text color */
        border: 1px solid #00ff00;
        /* Button border */
        border-radius: 5px;
        /* Slightly rounded corners */
        font-family: 'Courier New', Courier, monospace;
        /* Monospace font */
        font-size: 26px;
        align-items: center;
        justify-content: center;
}

button:hover {
    background-color: #00ff00;
    /* Background color on hover */
    color: #0d0208;
    /* Text color on hover */
}

table, th, td {
    border: 1px solid rgb(255, 248, 248);
    border-collapse: collapse;
}
th, td{
    padding: 10px;
}