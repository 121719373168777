.home_page * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.home_page label {
    font-size: 1.2rem;
    color: #656262;
}

/* Since html and body styles are global and affect the whole page, 
   you might not want to scope them to just '.home_page' unless 
   the entire page is within this '.home_page' container. */

.home_page {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(90deg,
            rgba(2, 0, 36, 1) 0%,
            rgba(143, 187, 204, 1) 35%,
            rgba(0, 212, 255, 1) 100%);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

/* ... (rest of your .home_page styles) ... */

.home_page .form_container {
    background-color: #fff;
    padding: 2rem 3rem;
    border-radius: 0.5rem;
    width: 100%;
    max-width: 550px;
    box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
}

.home_page .form_container>h2 {
    margin-block: 1rem;
    padding-block: 0.6rem;
    color: rgba(0, 212, 255, 1);
}

.home_page .form_container>form {
    display: flex;
    flex-direction: column;
    gap: 1.4rem;
}

.home_page .form_container div {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}

.home_page .form_container input {
    border: none;
    padding: 0.5rem;
    border-bottom: 1px solid gray;
    font-size: 1.1rem;
    outline: none;
}

.home_page .form_container input::placeholder {
    font-size: 0.9rem;
    font-style: italic;
}

.home_page .form_container button {
    background-color: rgba(0, 212, 255, 1);
    color: #fff;
    border: none;
    padding: 0.6rem;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 0.3rem;
}

.home_page span a {
    text-decoration: none;
    color: rgba(0, 212, 255, 1);
}

.home_page {
    background: #000;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 3rem;
    flex-direction: column;
    gap: 1rem;
}

.home_page span {
    color: rgba(0, 212, 255, 1);
}

.home_page button {
    background-color: rgb(27, 73, 83);
    color: #fff;
    cursor: pointer;
    padding: 1rem 3rem;
    font-size: 2rem;
    border-radius: 2rem;
    transition: ease-in 0.3s;
    border: none;
}

.home_page button:hover {
    background-color: rgba(0, 212, 255, 1);
}

@media only screen and (max-width: 1200px) {
    .home_page {
        font-size: 1.5rem;
    }

    .home_page button {
        padding: 0.6rem 1rem;
        font-size: 1.5rem;
    }
}

/* Media query also scoped to .home_page */
@media only screen and (max-width: 1200px) {
    .home_page {
        font-size: 1.5rem;
    }

    .home_page button {
        padding: 0.6rem 1rem;
        font-size: 1.5rem;
    }
}