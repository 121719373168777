/* Global styles scoped to the sign-up page */
.sign_up *,
.sign_up ::before,
.sign_up ::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
span{
    color: black;
}
html,
body {
    margin: 0;
    padding: 0;
}

.sign_up {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F1F1F1;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

/* Styles for the form container and its child elements */
.sign_up .form_container {
    background-color: #fff;
    padding: 2rem 3rem;
    border-radius: 0.5rem;
    width: 100%;
    max-width: 1000px;
    box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
}

.sign_up .form_container>h2 {
    margin-block: 1rem;
    padding-block: 0.6rem;
    text-align: center;
    color: #333;
}

.sign_up .form_container>form {
    display: flex;
    flex-direction: column;
    gap: 1.4rem;
}

.sign_up .form_container div {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}

.sign_up .form_container label {
    font-size: 1.2rem;
    color: #656262;
}

.sign_up .form_container input {
    border: none;
    padding: 0.5rem;
    border-bottom: 1px solid gray;
    font-size: 1.1rem;
    outline: none;
}

.sign_up .form_container input::placeholder {
    font-size: 0.9rem;
    font-style: italic;
}

/* .sign_up .form_container button {
    background-color: rgba(0, 212, 255, 1);
    color: #fff;
    border: none;
    padding: 0.6rem;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 0.3rem;
} */

.sign_up .form_container span a {
    text-decoration: none;
    color: rgba(0, 123, 255, 1);
}

/* Media queries, if any, can also be scoped to the sign-up page */
@media only screen and (max-width: 1200px) {
    .sign_up .form_container {
        /* Adjust styles for mobile view if necessary */
    }
}